import http from "../http-common";

class TeamDataService {
    putUserInTeam(userId) {
        console.log(userId)
        return http.get(`/teams/add/${userId}`)
    }

    getTeamById(id) {
        return http.get(`/teams/${id}`)
    }

    updateTeamById(id, data) {
        return http.put(`teams/${id}`, data)
    }

    getTeams(body) {
        return http.get(`/teams`, body)
    }

}

export default new TeamDataService();