import React, {userState, useState} from 'react';
import '../gameui.css'

export default function LocationInfo({location, getNextLocation, team, getPreviousLocation, loadingNextLocation}) {
    const [infoOpen, setInfoOpen] = useState(false)
    const [locationInfoShow, setLocationInfoShow] = useState(false)

    const handleOpenInfo = () => {
        setInfoOpen(!infoOpen)
        if(locationInfoShow == true) {
            setLocationInfoShow(!locationInfoShow)
        } else {
            setTimeout(() => {
                setLocationInfoShow(!locationInfoShow)
            }, 80);
        }
        console.log(location)
    }

    const handleNextStop = () => {
        if(loadingNextLocation == true) return
        handleOpenInfo()
        getNextLocation()
    }
    const handlePreviousStop = () => {
        if(loadingNextLocation == true) return
        handleOpenInfo()
        getPreviousLocation()
    }

    return <div className={`stop-container ${infoOpen ? "stop-container-open" :  ""}`}>
        {loadingNextLocation ? <h2>Loading...</h2>: <h2>{team.team_completed_stops == 0 || team.team_completed_stops > 0 ? `Stop ${team.team_completed_stops + 1}` : "Loading..." }</h2>}
        {locationInfoShow && <div className="location-info">
            <p>{loadingNextLocation ? "" : location.location_name}</p>
            <p>{loadingNextLocation ? "" : location.street_name}</p>
            <p>{loadingNextLocation ? "" : location.postalcode}</p> 
            <p>{loadingNextLocation ? "" : location.city}</p>
            </div>
        }
        <div className="btn-location-container">
            <button onClick={handleOpenInfo}className="btn btn-stop">{infoOpen ?  "Close" : "Open"}</button>
            {infoOpen && <button onClick={handleNextStop}className="btn btn-next">{loadingNextLocation ? "Loading":"Next stop"}</button>}
            {infoOpen && team.team_completed_stops != 0 && <button onClick={handlePreviousStop}className="btn btn-back">{loadingNextLocation ? "Loading":"Previous stop"}</button>}
        </div>
   
    </div>
}
