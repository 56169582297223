import './App.css';
import Login from './components/pages/login/Login';
import Homepage from './components/pages/homepage/Homepage';
import Welcome from './components/pages/welcome/Welcome';
import Game from './components/pages/game/Game';
import Rooms from './components/pages/rooms/Rooms';
import {useState, useEffect} from 'react'
import {BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import  UserDataService  from './services/user.service'
import Desktop from './components/desktop/Desktop';
import { useCookies } from 'react-cookie';
import Admin from './components/pages/admin/Admin';
import AdminAddRoom from './components/pages/admin/add-room/AddRoom';

function App() {
  const [width, setWidth] = useState(window.innerWidth);
  const [cookies, setCookie] = useCookies(['username', 'user_id']);

  const isMobile = width <= 485;

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  const appHeight = () => {
    const doc = document.documentElement
    doc.style.setProperty('--app-height', `${window.innerHeight}px`)
   }

   
   useEffect(() => {
     window.addEventListener('resize', handleWindowSizeChange);
     return () => {
       window.removeEventListener('resize', handleWindowSizeChange);
       window.addEventListener('resize', appHeight)
    }
  }, []);

  return (
    <div>
      {!isMobile ? <Desktop/> : 
       <div className="App" style={{width: '100vw', height: '100vh'}}>
       {/* <div className="App" > */}
       <div className="container">
         <Routes>
           <Route path="/" element={<Homepage/>} /> 
           <Route path="/login" element={<Login/>} /> 
           <Route path="/rooms" element={<Rooms/>} /> 
           <Route path="/welcome" element={<Welcome/>} /> 
           <Route path="/game" element={<Game/>} /> 
           <Route path="/admin" element={<Admin/>} /> 
           <Route path="/admin/addroom" element={<AdminAddRoom/>} /> 
         </Routes>
       </div>
     </div>
      }
     
    </div>
    
  );
}

export default App;
