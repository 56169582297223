import React, {useEffect} from 'react';
import { Link } from 'react-router-dom'

export default function LastStop({room, lastStop, backToPrevious, loadingNextLocation}) {
  useEffect(() => {
    console.log(lastStop)
  }, [])
  
  const handleback = () => {
    if(loadingNextLocation == true) return
    backToPrevious()
  }

  return <div className="last-stop-container">
      <h1>You’re almost there</h1>
      <p>Good job. Now get to {lastStop.last_stop_name} at {lastStop.last_stop_street_name} as fast as possible.</p>
      <ol>
          <li>Order beers for everyone in your group. </li>
          <li>Post a group image (including the beers) in {room.room_name} on Facebook.  </li>
      </ol>
      <div className='last-stop_btn-container'>
        <p>First group to complete this wins {room.room_name}.</p>
        <button className='btn btn-last-stop' onClick={handleback}>{loadingNextLocation ? "Loading.." : "Back to previous stop"}</button>
      </div>
  </div>;
}
