import http from "../http-common";

class LocationDataService {
    getAll() {
        return http.get(`/locations`)
    }

    getOneById(id) {
        return http.get(`/locations/${id}`)
    }
}

export default new LocationDataService();