import http from "../http-common";

class RoomDataService {
    getAll() {
        return http.get("/rooms")
    }

    getRoombyId(id) {
        return http.get(`/rooms/${id}`)
    }

    addUserToRoom(roomId, user) {
        return http.put(`/rooms/adduser/${roomId}`, user)
    }

    createRoom(data) {
        return http.post("/rooms", data)
    }

    closeRoom(roomId) {
        return http.put(`/rooms/close/${roomId}`)
    }

    resetRoom(roomId) {
        return http.put(`/rooms/reset/${roomId}`)
    }

    updateRoom(roomId, data) {
        return http.put(`/rooms/${roomId}`, data)
    }

    deleteRoom(roomId) {
        return http.delete(`/rooms/${roomId}`)
    }
}

export default new RoomDataService();