import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import roomService from '../../../../services/room.service'

export default function AdminRooms({rooms, setRoom, getRooms}) {
  const { handle } = useParams()
  let navigate = useNavigate();

  useEffect(() => {

  },[])
  
  function editRoom(id) {
    setRoom(id)
  }

  async function deleteRoom(roomId) {
    await roomService.deleteRoom(roomId)
    await getRooms()
  }

  function handleAddRoom() {
    navigate("addroom")
  }

  return (
    <div className="rooms-container">
      <div className="rooms-header">
      <h1>Rooms</h1>
      <button className="btn btn-secondary btn-addroom" onClick={handleAddRoom}></button>
      </div>
        {rooms.map(room => {
            return(
                <div className="room-container" key={room.room_id}>
                <div className="room-info">
                    <h2 >{room.room_name}</h2>
                    <p>Players in room: {room.room_players.length}</p>
                    <p className={room.room_isopen ? "room-open" : "room-closed"}>{room.room_isopen ? "Open" : "Closed"}</p>
                </div>
                <div className='admin-rooms_btn-container'>
                  <button onClick={() => editRoom(room._id) } className="btn btn-secondary">Edit</button> 
                  <button onClick={() => deleteRoom(room._id) } className="btn btn-secondary">Delete</button> 
                </div>
            </div>)
        })}
    </div>
  )
}
