import { useEffect, useState } from 'react'
import "./rooms.css"
import roomService from "../../../services/room.service"
import userService from '../../../services/user.service';
import { useCookies } from "react-cookie";
import { useNavigate  } from "react-router-dom";

export default function Rooms() {
    const [user, setUser] = useState({})
    const [rooms, setRooms] = useState([])
    const [cookies, setCookie] = useCookies(["user_id", "room_id"]);
    const id = cookies.user_id

    let navigate = useNavigate();

    useEffect(() => {
        const getRooms = async () => {
            try {
                const roomResp = await roomService.getAll()
                const roomData = roomResp.data
                setRooms(roomData)
                console.log(roomData)
                console.log(rooms)
                
            } catch (error) {
                
            }
        }

        const getUser = async () => {
            const resp = await userService.getUserById(id)
            setUser(resp.data)
        }

        getRooms().then(getUser())
    },[])
    
    function joinRoom(roomId) {
        roomService.addUserToRoom(roomId, user)
        setCookie("room_id", roomId, {path: '/', SameSite:"Lax", maxAge: 20000})
        navigate("/welcome")
    }

    function handleRejoin() {
        navigate("/game")
    }

    function renderButtons(room) {
        if(cookies.room_id == room._id) return <button onClick={handleRejoin} className='btn btn-secondary'>Re-join</button>
        else if(room.room_isopen)  return <button onClick={() => joinRoom(room._id) } className="btn btn-secondary">Join Room</button>
        else return <button className="btn btn-closed">Closed</button> 
    }

  return (<div className="roomlobby-container">
        <h1 className="rooms-header">Welcome {user?.user_name}</h1>
        <p className="rooms-subheader">Join an open room</p>
        <div className="rooms-container">
        {rooms.map(room => {
            return(
            <div className="room-container" key={room._id}>
                <div className="room-info">
                    <h2 >{room.room_name}</h2>
                    <p>Players in room: {room.room_players.length}</p>
                </div>
                {renderButtons(room)}
            </div>)
        })}
        </div>
      
      </div>
  )
}

