import React, { useState, useEffect} from 'react';
import './login.css'
import userService from '../../../services/user.service';
import { useNavigate  } from "react-router-dom";
import { useCookies } from 'react-cookie';

export default function Login() {
    const [userName, setUserName] = useState("");
    const [errorMsg, setErrorMsg] = useState(Boolean);
    const [cookies, setCookie] = useCookies(['user_id']);

    let navigate = useNavigate();

    useEffect(() => {
        if(cookies.user_id) navigate("/rooms")
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault();

        const userData = {
                user_name: userName
        }

        userService.createUser(userData).then(resp => {
            if(resp.data.success == false) {
                setErrorMsg(true)
            } 
            else {
                setCookie('user_id', resp.data._id, { path: '/', SameSite:"Lax", maxAge: 20000});
                navigate("/rooms")
            }
        })

    }

  return <div className="login-container">
      <h1>
            Join game
      </h1>
       {errorMsg &&
        <p className="error-msg">Username already exists</p>
       }
      <form action="" method="post" onSubmit={handleSubmit} className="login-form">
          <input type="text" placeholder="Username" value={userName} onChange={(e) => setUserName(e.target.value)}></input>
          <input className="btn btn-login" type="submit" value="Log in" />
      </form>
  </div>;
}
