import React, {useEffect, useState, useRef} from 'react';
import { GoogleMap, withScriptjs, withGoogleMap,  Marker, InfoWindow } from 'react-google-maps'
import userService from '../../../../services/user.service';

const defaultMapOptions = {
    fullscreenControl: false,
};

// const 

function Map({location, enemies}) {
    const mapRef = useRef(null);
    const [defaultCenter ,setDefaultCenter] = useState({
        // lat: location.lat, 
        // lng: location.lng
    })
    const [position, setPosition] = useState()
    const [defaultZoom ,setDefaultZoom] = useState()
    const [currentStop , setCurrentStop] = useState({})
    const [selectedLocation, setSelectedLocation] = useState(null)
    const [test , setTest] = useState([
        {id: 1, lat: 40, lng: 45},
        {id: 2,lat: 41, lng: 45},
        {id: 3,lat: 42, lng: 45},
        {id: 4,lat: 43, lng: 45},
    ])

    

    
    useEffect(() => {
        setMapStates()
        console.log("currentStop")
        console.log(location)
        console.log(enemies)
    }, [])
    // }, [location])
    
    async function setMapStates() {
        setCurrentStop(location)
        setDefaultCenter({
            lat: location.lat,
            lng: location.lng
        })
        
    }
    

    const handleMarkerClick = (e) => {
        console.log(e)
    }

    function handleLoad(map) {
        mapRef.current = map;
        console.log(mapRef)
    }

    function handleCenter() {
        if (!mapRef.current) return;
        const newPos = mapRef.current.getCenter().toJSON();
        console.log(newPos)
    
        setDefaultCenter(newPos);
        // setPosition(newPos);
    }

    function setTeamLogo(teamName) {
        switch (teamName) {
            case "Heineken":
                return "team-logos/heineken-logo.png";
            case "Carlsberg":
                return "team-logos/carlsberg-logo.png";
            case "Norrlands":
                return "team-logos/norrlands-logo.png";
            case "Perroni":
                return "team-logos/perroni-logo.png"
            case "Falcon":
                return "team-logos/falcon-logo.png";
            case "Småland":
                return "team-logos/smaland-logo.png"
            case "Mariestad":
                return "team-logos/mariestad-logo.png"
            case "Åbro":
                return "team-logos/abro-logo.png"
            case "Pripps":
                return "team-logos/pripps-logo.png"
            default:
                break;
        }
    }

    return <div>
    {location.lat && <GoogleMap
        onLoad={handleLoad}
        onDragEnd={handleCenter}
        defaultZoom={10}
        // defaultCenter={{lat: Number(location.lat),lng: Number(location.lng)}}
        defaultCenter={{lat: location.lat,lng: location.lng}}
        // defaultCenter={defaultCenter}
        // center={defaultCenter}
        // defaultCenter={{lat: defaultCenter.lat ,lng: defaultCenter.lng}}
        // defaultCenter={{lat: 0 ,lng: 0}}
        // center={{lat: Number(location.lat), lng: Number(location.lng)}}
        // center={position}
        options={{disableDefaultUI: true}}
        >
          <Marker 
            onClick={() => {
                handleMarkerClick("test")
                setSelectedLocation(location)
            }} 
            icon={{
                url: "icons/beer-icon.jpg",
                scaledSize: new window.google.maps.Size(50, 50)
            }}
            key={1} 
            position={{lat: Number(location.lat), lng: Number(location.lng)}} 
                > 
            {selectedLocation && (
                <InfoWindow
                    // position={{ 
                    // lat: location.lat + 0.0003, 
                    // lng: location.lng 
                    // }}
                    onCloseClick={() => {
                        setSelectedLocation(null)
                    }} >
                    <div>
                        <h2>{location.location_name}</h2>
                        <p>{location.street_name}</p>
                        {/* <a href="https://goo.gl/maps/5b35XomTqqf6buwN7" target="_blank">Get Directions</a> */}
                    </div>
                </InfoWindow>
            )

            }
            </Marker>
            {test.map(marker => {
                return <Marker  
          
                    key={marker.id} 
                    position={{ 
                        lat: marker.lat, 
                        lng: marker.lng 
                    }} 
                    > 
                </Marker>
            })}
            {enemies && enemies.map(enemy => {
                return <Marker
                    key={enemy._id} 
                    position={{ 
                        lat: Number(enemy.team_location.lat), 
                        lng: Number(enemy.team_location.lng) 
                    }} 
                    icon={{
                        url:setTeamLogo(enemy.team_name),
                        scaledSize: new window.google.maps.Size(55, 55)
                    }}
                >
                </Marker>
            })}
            {/* {selectedLocation && (
                <InfoWindow
                    position={{ 
                    lat: location.lat + 0.0003, 
                    lng: location.lng 
                    }}
                    onCloseClick={() => {
                        setSelectedLocation(null)
                    }} >
                    <div>
                        <h2>{location.location_name}</h2>
                        <p>{location.location_street_name}</p>
                        <a href="https://goo.gl/maps/5b35XomTqqf6buwN7" target="_blank">Get Directions</a>
                    </div>
                </InfoWindow>
            )

            } */}
        </GoogleMap>}
    </div>;
}

const WrappedMap = withScriptjs(withGoogleMap(Map))

export default WrappedMap;
