import axios from "axios";


const axiosConn = axios.create({
    headers: {
    "Content-type": "application/json"
    }
});

console.log(process.env)
console.log(window.location.hostname)

if (process.env.NODE_ENV === 'development') {
    axiosConn.defaults.baseURL = process.env.REACT_APP_BASE_URL_LOCAL;            
} else {
    if(process.env.NODE_ENV === 'production') {
       axiosConn.defaults.baseURL = process.env.REACT_APP_BASE_URL_PROD;
    } else {
       axiosConn.defaults.baseURL = process.env.REACT_APP_BASE_URL_DEV;
    }          
}

export default axiosConn;

// export default axios.create({
//   baseURL: 'http://localhost:8080/api',
//   headers: {
//     "Content-type": "application/json"
//   }
// });