import React, { useState } from 'react'
import roomService from '../../../../services/room.service';
import { useNavigate } from 'react-router-dom';


export default function AddRoom() {
    const [inputs, setInputs] = useState({room_isopen: true})   
    
    let navigate = useNavigate();

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value != "on" ? event.target.value : event.target.checked;
        setInputs(values => ({...values, [name]:value}))
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log(inputs)
        await roomService.createRoom(inputs)
        navigate("/admin")
    }

    const handleBackbtn = () => {
        navigate("/admin")
    }

  return (
    <div className='admin-room-container'>
        <h1>Add room</h1>
        <form className="admin-addroom-form" onSubmit={handleSubmit}>
            <label> Room name:
                <input 
                    type="text"
                    name="room_name"
                    value={inputs.room_name || ""}
                    onChange={handleChange}
                />
            </label>
            <label htmlFor=""> Is room open?
                <input 
                    type="checkbox" 
                    name="room_isopen"
                    // value={inputs.room_isOpen || false}
                    checked={inputs.room_isopen || false}
                    onChange={handleChange}
                />
            </label>
            <label> Locations:
            <input 
              type="number" 
              min="1" 
              max="100" 
              name="room_max_stops"
              value={inputs.room_max_stops || 1}
              onChange={handleChange}
            />
          </label>
          <label> Team size:
            <input 
              type="number" 
              min="1" 
              max="100"
              name="room_team_size"
              value={inputs.room_team_size || 1}
              onChange={handleChange}
            />
          </label>
            <input className='btn btn-secondary' type="submit" value="Add room" />
            
        </form>
        <button className='btn btn-secondary' onClick={handleBackbtn}>Back</button>

    </div>
  )
}
