import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import './homepage.css'
import { useCookies } from 'react-cookie';

export default function Homepage() {
  const [cookies, setCookie, removeCookie] = useCookies(['user_id', 'room_id']);

  useEffect(() => {
    handleResetCookies()
  }, [])

  function handleResetCookies() {
    console.log("reset Coikies")
    removeCookie('room_id')
    removeCookie('user_id')
  }

  return <div className="homepage-container">
      <h1 className="">Strøgagillet 2022</h1>
      <p>What better to do on a saturday than to get absolutly blasted with your friends and terrorize the streets of Stockholm. To take part in Strøgagillet 2022 you have to be in Fatbursparken on the 30th April 14:00 and visit this website on your mobile phone. See you there!</p>
        <Link to={"/login"}>

        <button className="btn btn-joingame" onClick={handleResetCookies} >Join game</button>
        </Link>
  </div>;
}
