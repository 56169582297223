import React, { useEffect } from 'react';
import '../gameui.css'

export default function Progressbar({team, user}) {

    useEffect(() => {

    }, [team.team_completed_stops])
    
    function progressbarItem(totalItems) {
        const array = []

        for(var i = 1; i <= totalItems; i++){
          array.push(<div key={i} id={i} className={`progressbar-item ${team.team_completed_stops >= i ? "progressbar-item-active" :  ""}`}></div>)
        }
    
        return array
    }

    return <div>
      <div className="progressbar-container">
        {/* {`Team ${team.team_name} ${user.user_name} ${team.team_completed_stops}`} */}
          <div className="progressbar">
              {progressbarItem(team.team_total_stops)}
          </div>
          </div>
          <div className="progressbar-team-members">
            {team.team_members && team.team_members.map(member => {
              if(member.user_name == user.user_name) return 
              return <div 
              className='team-member'
              key={member._id}>
                <ul>
                  <li>
                    <p>
                      {member.user_name}
                    </p>
                  </li>
                </ul>
              </div>
            })}
            </div>
    </div>;
}
