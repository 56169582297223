import React, {useState, useEffect} from 'react';
import { useCookies } from "react-cookie";
import { Link } from 'react-router-dom';
import './welcome.css'
import Rulebook from './rulebook-modal/Rulebook';
import roomService from '../../../services/room.service';
import teamService from '../../../services/team.service';
import userService from '../../../services/user.service';
import { useNavigate  } from "react-router-dom";


export default function Welcome() {
    const [user, setUser] = useState({})
    const [room, setRoom] = useState({})
    const [cookies, setCookie] = useCookies(["username", "user_id", "user_team", "room_id"]);
    const [showRulebook, setRulebook] = useState(false)
    const [roomStatus, setRoomStatus] = useState("")

    let roomStatusInterval = ""

    let navigate = useNavigate();

    useEffect(()  => {
        getUser()
        getRoom()
        // getTeam()
        startRoomInterval()
    },[])
    
    async function getUser() {
        const id = cookies.user_id
        const resp = await userService.getUserById(id)
        console.log(resp.data)
        setUser(resp.data)
        const user = resp.data
        console.log(user.user_current_team)
        // if(user.user_current_team == null || user.user_current_team == undefined) putUserInTeam(id) 
            // else {
                // setTeamName(user.user_current_team)
                // setCookie("user_team", user.user_current_team, { path: '/', SameSite:"Lax"})
            // }

    }

    async function getRoom() {
        const roomId = cookies.room_id;
        const roomData = await roomService.getRoombyId(roomId);
        console.log(roomData)
        setRoom(roomData.data)
    }

    async function  checkRoomStatus() {
        const roomId = cookies.room_id;
        let room = await roomService.getRoombyId(roomId).then(resp => {
            console.log(resp)
            setRoomStatus(resp.data.room_isopen)
            if(resp.data.room_isopen == 0) {
                navigate("/game")
                clearInterval(roomStatusInterval)
            }
        })
    }

    function startRoomInterval() {
        roomStatusInterval = setInterval(() => {
            checkRoomStatus()
        }, 8000);
    }

    function handleRulebook() {
        setRulebook(true)
    }

    function handleBackToLobby() {

    }

  return <div>
      {showRulebook && 
        <Rulebook stateChanger={setRulebook}></Rulebook>
      }
      <div className="welcome-top">
          <h1 className="">Welcome to {room.room_name}</h1>
      </div>
      <div className="welcome-bottom">
          <h2>The game is about to begin.</h2>
          <p>Please read the rule book below before
            entering today’s activity. This page will
            automatically refresh when the game has
            been started by the commander.</p>
            <div className='welcome-btn-container'>
                <button className="btn btn-rulebook" onClick={handleRulebook}>Rule book</button>
                {/* <button className="btn btn-back" onClick={handleBackToLobby}>Back to lobby</button> */}
            </div>
      </div>

  </div>;
}
