import React from 'react'

export default function TeamModal({team, user, toggleModal}) {

    function setTeamLogo(team) {
        switch (team.team_name) {
            case "Heineken":
                return "team-logos/heineken-logo.png";
            case "Carlsberg":
                return "team-logos/carlsberg-logo.png";
            case "Norrlands":
                return "team-logos/norrlands-logo.png";
            case "Perroni":
                return "team-logos/perroni-logo.png"
            case "Falcon":
                return "team-logos/falcon-logo.png";
            case "Småland":
                return "team-logos/smaland-logo.png"
            case "Mariestad":
                return "team-logos/mariestad-logo.png"
            case "Åbro":
                return "team-logos/abro-logo.png"
            case "Pripps":
                return "team-logos/pripps-logo.png"
            default:
                break;
        }
    }

    function handleClose() {
        toggleModal()
    }

  return (
      <div className="team-modal-container">
        <div className='team-modal'>
            <h2>Welcome to team</h2>
           {team.team_name ?  <img className="team-modal-logo" src={setTeamLogo(team)} alt={`Team ${team.team_name} logo`} /> : "Loading..."}
            <h3>Team members:</h3>
            <div className="team-modal_members-container">
                {team.team_members && team.team_members.length ? team.team_members.map(member => {
                if(member.user_name == user.user_name) return
                if(team.team_members.length == 0 ) return <p>You are the loneliest man in the world!</p>
                
                return <div 
                className='team-member'
                key={member._id}>
                        <p>
                        {member.user_name}
                        </p>
                </div>
                }) : "Loading..."}
            </div>
            <button className="btn btn-secondary" onClick={handleClose}>Close</button>
        </div>
      </div>
  )
}
