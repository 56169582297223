import { useState, useEffect } from 'react'
import roomService from '../../../../services/room.service'

export default function AdminRoom({room, rooms, goBack, getRoom}) {
  console.log("re-render")
  const [roomData, setRoomData] = useState() 
  const [inputs, setInputs] = useState({})  
  const [validation, setValidation] = useState()

  useEffect(() => {
    console.log(room)
    console.log(rooms)
    handleRoomData(room)
  },[room])

  const handleRemoveRoom = () => {
    goBack()
  }

  const handleRoomData = async (roomId) => {
    const room = await rooms.filter(room => room._id == roomId)
    console.log(roomId)
    console.log(room)
    setRoomData(room)
    console.log(roomData)
  }

  const handleCloseRoom = async (roomId) => {
    console.log(roomId)
    await roomService.closeRoom(roomId);
  }
  
  const handleResetRoom = async (roomId) => {
    await roomService.resetRoom(roomId);
  }

  const handleSubmitRoom = async (event) => {
    event.preventDefault();
    console.log(roomData, inputs)
    const resp =  await roomService.updateRoom(roomData[0]._id, inputs);
    console.log(resp)
    // getRoom(roomData[0]._id)
    if(resp.status == 200) setValidation(true)
    await getRoom()
    handleRoomData(room)

  }

  const handleChange = (event) => {
    console.log(event)
    const name = event.target.name;
    const value = event.target.value;
    if(event.target.name == "last_stop_name" || event.target.name == "last_stop_street_name") {
      setInputs(values => ({...values, room_last_stop: { ...values.room_last_stop, [name]:value }}))
      console.log("testy1")
      console.log(inputs)
      
    } else {
      setInputs(values => ({...values, [name]:value}))
      console.log("testy")
    }
  }




  return (
    <div className='admin-room-container'>
      <div className='admin-room-header'>
        <h1>{roomData && roomData[0].room_name}</h1>
      </div>
     {roomData && 
     <div>
       <h2>Room Info</h2>
       <div className='admin-room-info-container'>
        <div>
          <h4>Players</h4>
          <div className='admin-room-info-box'>
          {roomData[0].room_players.map(player => {
            return <p key={player._id} >{player.user_name}</p>
          })}
          </div>
        </div>
        <div>
          <h4>Locations</h4>
          <div className='admin-room-info-box'>
          {roomData[0].room_locations.map(location => {
            return <p key={location._id}>{location.location_name}</p>
          })}
          </div>
        </div>
       </div>
          <h2>Change Room Info</h2>
      <form onSubmit={handleSubmitRoom}>
        <div className="admin-room_input-container">
          <label> Room name:
            <input 
                type="text"
                name="room_name"
                defaultValue={roomData[0].room_name}
                value={inputs.room_name}
                onChange={handleChange}
            />
          </label>
          <label> Max Locations:
            <input 
              type="number" 
              min="1" 
              max="100" 
              name="room_max_stops"
              defaultValue={roomData[0].room_max_stops}
              onChange={handleChange}
            />
          </label>
          <label> Team size:
            <input 
              type="number" 
              min="1" 
              max="100"
              name="room_team_size"
              defaultValue={roomData[0].room_team_size}
              onChange={handleChange}
            />
          </label>
          <label> Last stop name:
            <input 
              type="text" 
              name="last_stop_name"
              defaultValue={roomData[0].room_last_stop.last_stop_name}
              value={inputs.last_stop_name}
              onChange={handleChange}
            />
          </label>
          <label> Last stop adress:
            <input 
              type="text" 
              name="last_stop_street_name"
              defaultValue={roomData[0].room_last_stop.last_stop_street_name}
              value={inputs.last_stop_street_name}
              onChange={handleChange}
            />
          </label>
          </div>
          <input className='btn btn-secondary' type="submit" value={validation ? "Changes submited" : "Submit changes"} />
        </form>
        <h2>Room Operations</h2>
        <div className="admin-room-btn-container">
          <button className='btn btn-secondary' onClick={() => handleCloseRoom(roomData[0]._id)}>Close room</button>
          <button className='btn btn-secondary' onClick={() => handleResetRoom(roomData[0]._id)}>Reset room</button>
        </div>
      </div>}
        <button className='btn btn-secondary btn-admin-back' onClick={handleRemoveRoom}>Back to rooms</button>
    </div>
  )
}
