import {useState, useEffect} from 'react'
import { useCookies } from "react-cookie";

import LastStop from './game-ui/last-stop/LastStop';
import LocationInfo from './game-ui/location-bottom/LocationInfo';
import Progressbar from './game-ui/progressbar/Progressbar';
import GameUI from './game-ui/GameUI'
import Map from './map/Map'
import roomService from '../../../services/room.service';
import teamService from '../../../services/team.service';
import userService from '../../../services/user.service';
import locationService from '../../../services/location.service';
import TeamModal from './game-ui/team-modal/TeamModal';



export default function Game() {
    const [enemies, setEnemies] = useState()
    const [currentLocation, setCurrentLocation] = useState({})
    const [cookies, setCookie] = useCookies([ "user_id", "team_id", "room_id"]);
    const [user, setUser] = useState({})
    const [team, setTeam] = useState({})
    const [room, setRoom] = useState({})
    const [userGeoLocation, setUserGeoLocation] = useState()
    const [openModal, setOpenModal] = useState(true)
    const [loadingNextLocation, setLoadingNextLocation] = useState(false)

      let setLocationInterval = "";
      let getEnemiesLocationInterval = "";
      
    useEffect(() => {
        const id = cookies.user_id
        console.log(id)
        getRoom()
        // getUserCurrentLocation(id)
        getUser(id)
      }, [])
      
      async function getUser(id) {
        const resp = await userService.getUserById(id)
        console.log(resp)
        setUser(resp.data)
        getTeam(resp.data.user_current_team)
        setUserCurrentLocation()
        startRoomIntervals(resp.data)
      }
      
      async function getTeam(id) {
        const resp = await teamService.getTeamById(id)
        console.log(resp)
        const team = resp.data[0]
        setTeam(team)

        console.log(team.team_completed_stops, team.team_stops.length)
        if(team.team_completed_stops >= team.team_stops.length) {
          console.log("tefsafsd", team.team_stops[team.team_stops.length])
          setCurrentLocation(team.team_stops[team.team_stops.length - 1])
        } else {
          console.log("32141tefsafsd")
          setCurrentLocation(team.team_stops[team.team_completed_stops])
        }
    }


    async function getRoom() {
      const roomId = cookies.room_id;
      const resp = await roomService.getRoombyId(roomId)
      console.log(resp)
      setRoom(resp.data)
      getEnemyLocation(roomId)
    }



    async function getEnemyLocation(roomId) {
      const query = {
        team_room_info: roomId
      }
      const resp = await teamService.getTeams(query)
      setEnemies(resp.data)
    } 

    async function getNextLocation() {
      setLoadingNextLocation(true)
      try {
        const teamResp = await teamService.getTeamById(team.team_id)

        console.log(teamResp)
        if(teamResp.data[0].team_completed_stops > team.team_completed_stops || teamResp.data[0].team_completed_stops < team.team_completed_stops) {
          console.log("test")
          setTeam(teamResp.data[0])
          setCurrentLocation(team.team_stops[teamResp.data[0].team_completed_stops])
          return
        }
        console.log("test1")

        const resp = await teamService.updateTeamById(team._id, {
          team_completed_stops: team.team_completed_stops + 1 
        })
        console.log(resp)
        setTeam(resp.data.data)

        if(resp.data.data.team_completed_stops >= resp.data.data.team_stops.length) {
          setCurrentLocation(team.team_stops[resp.data.data.team_stops.length - 1])
        } else {
          setCurrentLocation(team.team_stops[resp.data.data.team_completed_stops])
        }
        
      } catch (error) {
        console.log(error)
      } finally {
        setLoadingNextLocation(false)
      }
    }

    async function getPreviousLocation() {
      console.log("previos")
      setLoadingNextLocation(true)
      try {
        const teamResp = await teamService.getTeamById(team.team_id)

        if(teamResp.data[0].team_completed_stops == 0) return

        else if(teamResp.data[0].team_completed_stops < team.team_completed_stops){
          setTeam(teamResp.data[0])
          setCurrentLocation(team.team_stops[teamResp.data[0].team_completed_stops])
          return
        }

        console.log("test2")

        const resp = await teamService.updateTeamById(team._id, {
          team_completed_stops: team.team_completed_stops - 1 
        })
        setTeam(resp.data.data)
        setCurrentLocation(team.team_stops[resp.data.data.team_completed_stops])
      } catch (error) {
        
      }finally {
        setLoadingNextLocation(false)
      }
    }

    function startRoomIntervals(user) {
      setLocationInterval = setInterval(() => {
          setUserCurrentLocation()
          console.log("set location interval")
        }, 45000);
        
        getEnemiesLocationInterval = setInterval(() => {
          console.log("get enemy interval")
          getEnemyLocation(cookies.room_id)
        }, 60000)
    }
    
    function stopInterval() {
      clearInterval(setLocationInterval)
      
    }
  
    function setUserCurrentLocation() {
      if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(getCoordinates)
      }
    }
  
    async function getCoordinates(position) {
      const userData = {
        user_location: {
          lat: (position.coords.latitude).toString(),
          lng: (position.coords.longitude).toString()
        }
      }
      console.log(userData)
      setUserGeoLocation(userData)
      updateUserLocation(user, userData)
      }
      
      
    async function updateUserLocation(user, userGeoData) {
      try {
        console.log("updating user loaction", userGeoData)
        await userService.updateUser(cookies.user_id, userGeoData)
        
      } catch (error) {
        console.log(error)
      }
    }

    function toggleModal() {
      setOpenModal(!openModal)
    }

    function handleModalToggle() {

    }


    
    var {lat, lng, ...other} = currentLocation;
  return <div className="game-container" style={{width: '100vw',  height: '85vh', position: 'relative'}}>

      {team.team_completed_stops >= team.team_total_stops ? 
        <LastStop lastStop={room.room_last_stop} backToPrevious={getPreviousLocation}loadingNextLocation={loadingNextLocation} room={room}></LastStop> : 
        <div className="game-container" style={{width: '100vw',height: '100vh', position: 'relative'}}>

          {openModal ? <TeamModal team={team} user={user} toggleModal={toggleModal}></TeamModal> : ""}

          <Progressbar team={team} user={user} location={currentLocation}/>
          
          <LocationInfo location={currentLocation} getNextLocation={getNextLocation} getPreviousLocation={getPreviousLocation} team={team} loadingNextLocation={loadingNextLocation}/>

          <div className="toggleTeamModal" onClick={toggleModal}></div>

          {currentLocation && <Map
              googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyAAtINI09WYpUi1jX8bxJReCnBao0yLhks`}
              loadingElement={<div style={{ height: "100%"}}/>}
              containerElement={<div style={{ height: "100%"}}/>}
              mapElement={<div style={{ height: "90%"}}/>}
              location={ {lat: Number(currentLocation.lat), lng: Number(currentLocation.lng), ...other} }
              enemies={enemies}
            ></Map>}

      </div>

      } 
  </div>;
}
