import http from "../http-common";

class UserDataService {
    getAll() {
        return http.get("/users")
    }

    getUserById(id) {
        console.log(id)
        return http.get(`/users/${id}`)
    }

    getAllOtherPlayers(teamId) {
        return http.get(`/users/enemies/${teamId}`)
    }

    createUser(data)  {
        return http.post("/users", data)
    }

    updateUser(id, data) {
        return http.put(`/users/${id}`, data)
    }
}

export default new UserDataService();