import React from 'react';
import './rulebook.css'

export default function Rulebook({stateChanger, ...rest}) {
    function handleClose() {
        stateChanger(false)
    }
  return <div className="rulebook-container">
          <h1>Rule book</h1>
          <ol>
            <li>Only walking is permitted.</li>
            <li>When the game begins you while be assigned to a team, find your teammates and head towards the first location.</li>
            <li>Every member of each team should consume 1 unit per location,small, large or grande, your choice!</li>
            <li>After finishing a location, get next location in the app</li>
            <li>If your team is the first to arrive at the final location, order a round of beverages and post a image to the facebook group, you are now the winners!</li>
            <li>At the last location, we waiting for everyone to finish the race.</li>
          </ol>
          <button className="btn btn-close-rulebook" onClick={handleClose}>Close</button>
  </div>;
}
