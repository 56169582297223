import { useEffect, useState } from 'react'
import './admin.css'
import roomService from '../../../services/room.service'
import AdminRooms from './admin-rooms/AdminRooms'
import AdminRoom from './admin-room/AdminRoom'

export default function Admin() {
    const [rooms, setRooms] = useState([]);
    const [room, setRoom] = useState();

    
    useEffect(() => {

        getRooms()
    },[])
    
    function goBack() {
        setRoom()
    }

    const getRooms = async () => {
        try {
            const roomResp = await roomService.getAll()
            const roomData = roomResp.data
            setRooms(roomData)
            console.log(roomData)
            console.log(rooms)
            
        } catch (error) {
            
        }
    }


    const getRoom = async (roomId) => {
        console.log(roomId)
        try {
            const roomResp = await roomService.getRoombyId(roomId)
            console.log(roomResp)
            const roomData = roomResp.data
            setRoom(roomData)
            
        } catch (error) {
            console.log(error)
        }
    }

  return (
    <div className='admin-container'>
        {room ? <AdminRoom room={room} rooms={rooms} goBack={goBack} getRoom={getRooms}></AdminRoom> :
            <AdminRooms  getRooms={getRooms} rooms={rooms} setRoom={setRoom}></AdminRooms>
        }
    </div>
  )
}
